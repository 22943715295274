// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

import $ from 'jquery';
global.$ = jQuery;

import 'foundation-sites/dist/js/foundation';
import 'cocoon/app/assets/javascripts/cocoon';
import 'motion-ui/dist/motion-ui.js';
import 'motion-ui/dist/motion-ui.js';
import 'tablesorter/dist/js/jquery.tablesorter.min';

// Complete SortableJS (with all plugins)
import Sortable from 'sortablejs';
global.Sortable = Sortable;

$(document).on('turbolinks:load', function() {


    $(document).foundation();
    $(window).trigger('load.zf.sticky');

      $(".table").tablesorter({theme: "blue"});
      // hide child rows


      $(".tablereport").tablesorter({
          theme : 'blue',
          // this is the default setting
          cssChildRow: "tablesorter-childRow"
        })

        $('.tablereport').delegate('.toggle', 'click' ,function() {
            $(this).closest('tr').nextUntil('tr:not(.tablesorter-childRow)').find('td').toggle();
            return false;
        });

        $('.tablesorter-childRow td').hide();

        // SCROLL
        $(function() {
          $(window).scroll(function() {
            var winTop = $(window).scrollTop();
            if (winTop >= 69) {
              $("header").addClass("sticky-header");
            } else{
              $("header").removeClass("sticky-header");
            }
          });
        });

        // LISTES DEROULANTES DYNAMIQUES
        $('#equipment_brand_id').change(function(){
            var brand_id = this.value
            var equipment_type_id = document.querySelector('#equipment_equipment_type_id').value;

            if(equipment_type_id == "undefined" || equipment_type_id == "" || equipment_type_id == 0)
            {
                equipment_type_id = 'all'
            }
            if(brand_id != "undefined" && brand_id != 0)
            {
                $.ajax({
                    url : "/configuration/values/brands/list_brand_models/"+brand_id+"/"+equipment_type_id,
                })
            }
        });

        $('#equipment_equipment_type_id').change(function(){

            var equipment_type_id = document.querySelector('#equipment_equipment_type_id').value;
            var brand_id = document.querySelector('#equipment_brand_id').value;

            if(equipment_type_id == "undefined" || equipment_type_id == "" || equipment_type_id == 0)
            {
                equipment_type_id = 'all'
            }
            if(brand_id != "undefined" && brand_id != 0)
            {
                $.ajax({
                    url : "/configuration/values/brands/list_brand_models/"+brand_id+"/"+equipment_type_id,
                })
            }
        });

        // Supprimer une photo d'un équipement
        $('a[data-remote]').on('ajax:success', function(event){
            if(this.dataset.photo != 'undefined' && this.dataset.action == 'equipment_photo')
            {
                $('#photo_'+ this.dataset.photo).remove();
            }
        })
        // Supprimer une photo d'un équipement
        $('a[data-remote]').on('ajax:success', function(event){
            if(this.dataset.photo != 'undefined' && this.dataset.action == 'equipment_miniature')
            {
                $('#miniature_'+ this.dataset.photo).remove();
            }
        })

        // Active/Désactive la visibilité d'une photo d'un équipement
        $('a[data-remote]').on('ajax:success', function(event){
            if(this.dataset.photoId != 'undefined' && this.dataset.action == 'visibility_photo')
            {
                if (this.dataset.visible == 'true') {
                    $('#visibility_photo_'+ this.dataset.photoId).text("afficher");
                    this.dataset.visible = 'false';
                }else if (this.dataset.visible == 'false') {
                    $('#visibility_photo_'+ this.dataset.photoId).text("masquer");
                    this.dataset.visible = 'true';
                }
            }
        })




        setTimeout(function(){
        $('#notice').fadeOut();
        $('#flash').fadeOut();
        }, 2000);

        setTimeout(function(){
        $('#alert').fadeOut();
    }, 4000);


    // Supprimer une photo d'un appareil (Device)
    $('a[data-remote]').on('ajax:success', function(event){
        if(this.dataset.photo != 'undefined' && this.dataset.action == 'device_photo')
        {
            $('#photo_'+ this.dataset.photo).remove();
        }
    })
    // Supprimer un document d'un employée (Employee)
    $('a[data-remote]').on('ajax:success', function(event){
        if(this.dataset.document != 'undefined' && this.dataset.action == 'employee_document')
        {
            $('#document_'+ this.dataset.document).remove();
        }
    })
    // Supprimer une dépsense d'un employée (Expenses)
    $('a[data-remote]').on('ajax:success', function(event){
        if(this.dataset.photo != 'undefined' && this.dataset.action == 'employee_expense_photo')
        {
            $('#photo_'+ this.dataset.photo).remove();
        }
    })

});
